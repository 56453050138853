import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true, 
        themes: {
            dark: {
                primary: '#982c3c',
                secondary: '#fff',
                accent: '#982c3c',
                error: '#b71c1c',
            },
        },
    },
});
