import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueCarousel from 'vue-carousel';
import Axios from 'axios'
import AOS from 'aos';
import 'aos/dist/aos.css';
Vue.use(VueCarousel);
Vue.prototype.$http = Axios;

Vue.config.productionTip = false;

AOS.init();

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
